 v <template>
  <div class="px-2">
    <div class="overflow-y-auto scroltype h-[400px]">
      <div class="p-5 max-h-screen overflow-y-auto xl:pb-1 pb-10">
        <div class="grid grid-cols-1 gap-0">
          <div>
            <!-- anahtar kelimeler -->
            <tagInput
              v-model="list.keywords"
              :isEdit="isEdit"
              :required="true"
              :title="$t('project.components.add.keywords')"
              :placeholder="$t('project.components.add.keywordsPlaceholder')"
              :maxCount="55"
              :minCount="3"
              :excludedTags="list.innovations"
              source="keywords"
            />
          </div>

          <div>
            <!-- yetenekler  -->
            <tagInput
              v-model="list.innovations"
              :isEdit="isEdit"
              :required="true"
              :title="$t('project.components.add.skills')"
              :placeholder="$t('project.components.add.skills')"
              :maxCount="55"
              :minCount="3"
              :excludedTags="list.keywords"
              source="innovations"
            />
          </div>

          <div class="w-full">
            <customInput
              v-model="list.note"
              :title="$t('project.components.add.notes')"
              :placeholder="$t('project.components.add.note')"
              textarea-class="resize-none"
              type="textarea"
              max="500"
              :rows="2"
              :tooltip="true"
              :tooltip-title="$t('project.components.add.noteTooltip')"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import customInput from "@/components/customInput.vue";
import tagInput from "@/components/tagInput.vue";

export default {
  name: "stepthreePage",
  props: ["isEdit"],
  components: {
    customInput,
    tagInput,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      list: (state) => state.project.stepData.stepThree,
      project: (state) => state.project,
    }),
  },
};
</script>
