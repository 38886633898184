<template>
  <modal
    name="edit-contact"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '30%'"
    :scrollable="true"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">
        {{ $t("cv.profile.information.contact.edit.edit") }}
      </h1>
      <button @click="hide()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <form
      @submit.prevent="isEdit ? edit() : save()"
      class="p-5 max-h-screen overflow-y-auto xl:pb-5 pb-20"
      novalidate
    >
      <div class="space-y-4 pb-3">
        <customInput
          v-model="icon"
          :disabled="true"
          type="select"
          :selectList="typeList"
          :required="true"
          :is-error="true"
          ref="icon"
          :placeholder="
            $t('cv.profile.information.contact.edit.typePlacholder')
          "
          :title="$t('cv.profile.information.contact.edit.type')"
        />

        <div v-if="icon == 4">
          <customInput
            v-model="type"
            type="text"
            min="2"
            max="25"
            :required="true"
            :is-error="true"
            ref="type"
            :placeholder="
              $t(
                'cv.profile.information.contact.edit.socialMediaNamePlacholder'
              )
            "
            :title="$t('cv.profile.information.contact.edit.socialMediaName')"
          />
        </div>
        <customInput
          v-if="icon"
          v-model="value"
          :type="isTypeValue"
          min="2"
          :required="true"
          :is-error="true"
          ref="value"
          :placeholder="
            $t('cv.profile.information.contact.edit.contactAddress')
          "
          :title="
            (icon == 4
              ? $t('cv.profile.information.contact.edit.socialMediaName')
              : contactName(icon)) +
            $t('cv.profile.information.contact.edit.enter')
          "
        />
        <requiredField />

        <div
          class="flex gap-10 items-end max-sm:flex-wrap max-sm:gap-3 max-sm:pt-0"
        >
          <div class="w-full">
            <buttonItem
              :load="load"
              :name="$t('cv.profile.information.contact.edit.save')"
              buttonClass="w-full"
            />
          </div>
        </div>
      </div>
    </form>
  </modal>
</template>

<script>
import customInput from "@/components/customInput.vue";
import buttonItem from "@/components/button.vue";
import requiredField from "@/components/requiredField.vue";

import { contact } from "@/networking/urlmanager.js";

export default {
  name: "edit-language",
  props: ["isEdit", "Detail", "index", "contactName"],
  components: {
    customInput,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      isModalOver: false,
      load: false,

      rowId: "",
      type: "",
      icon: 1,
      value: "",

      typeList: [
        // {
        //   value: 0,
        //   name: this.$t("cv.profile.information.contact.add.choose"),
        // },
        {
          value: 1,
          name: this.$t("cv.profile.information.contact.add.email"),
        },
        {
          value: 2,
          name: this.$t("cv.profile.information.contact.add.phone"),
        },
        {
          value: 3,
          name: this.$t("cv.profile.information.contact.add.adress"),
        },
        {
          value: 4,
          name: this.$t("cv.profile.information.contact.add.socialMedia"),
        },
        {
          value: 5,
          name: this.$t("cv.profile.information.contact.add.webSite"),
        },
      ],
    };
  },
  computed: {
    isTypeValue() {
      switch (Number(this.icon)) {
        case 1:
          return "email";
        case 2:
          return "text";
        case 3:
        case 4:
          return "text";
        case 5:
          return "url";
        default:
          return "text";
      }
    },
  },
  methods: {
    show() {
      setTimeout(async () => {
        this.rowId = this.Detail.id;
        this.type = this.Detail.type;
        this.icon = this.Detail.icon;
        this.value = this.Detail.value;
      }, 50);
      this.$modal.show("edit-contact");
    },
    hide() {
      this.$modal.hide("edit-contact");
    },
    async validateForm() {
      let isValid = true;

      // Validate icon selection
      const iconValid = this.$refs.icon.validateInput();
      isValid = isValid && iconValid;

      // If icon is selected, validate other fields
      if (this.icon !== "") {
        // Validate type field for social media
        if (this.icon === 4 && this.$refs.type) {
          const typeValid = this.$refs.type.validateInput();
          isValid = isValid && typeValid;
        }

        // Validate value field
        if (this.$refs.value) {
          const valueValid = this.$refs.value.validateInput();
          isValid = isValid && valueValid;
        }
      }

      // Additional validation based on type
      if (this.icon === 1) { // Email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.value)) {
          this.$refs.value.setError(this.$t("validation.invalidEmail"));
          isValid = false;
        }
      // } else if (this.icon === 2) { // Phone
      //   const phoneRegex = /^\+?[\d\s-]{8,}$/;
      //   if (!phoneRegex.test(this.value)) {
      //     this.$refs.value.setError(this.$t("validation.invalidPhone"));
      //     isValid = false;
      //   }
      } else if (this.icon === 5) { // Website
        const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
        if (!urlRegex.test(this.value)) {
          this.$refs.value.setError(this.$t("validation.invalidUrl"));
          isValid = false;
        }
      }

      return isValid;
    },
    async save() {
      this.load = true;
      const isValid = await this.validateForm();

      if (isValid && this.icon && this.value?.trim()) {
        this.$emit("loadChance", true);
        this.$store.commit("editContact", {
          index: this.index,
          list: {
            icon: this.icon,
            type: this.icon == 4 ? this.type : this.contactName(this.icon),
            value: this.value,
          },
        });

        this.icon = "";
        this.type = "";
        this.value = "";
        this.successMessage();

        this.load = false;
        this.$emit("loadChance", true);
        this.hide();
      } else {
        this.load = false;
      }
    },
    async edit() {      
      this.load = true;
      const isValid = await this.validateForm();

      if (isValid && this.icon && String(this.value)?.trim()) {
        let list = [
          {
            icon: this.icon,
            type: this.icon == 4 ? this.type : this.contactName(this.icon),
            value: String(this.value),
          },
        ];
        
        try {
          await this.axios.post(contact.edit, {
            rowId: this.rowId,
            cvId: this.$route.params.id,
            list: JSON.stringify(list),
          });
          
          this.successMessage();
          this.$emit("refresh", true);
          this.hide();
        } catch (err) {
          console.error(err);
          this.authController(err);
        } finally {
          this.load = false;
        }
      } else {
        this.load = false;
      }
    },
  },
};
</script>
