<template>
  <modal
    name="addProject"
    :height="isMobile ? '100%' : 'auto'"
    :width="isMobile ? '100%' : '55%'"
    :scrollable="true"
    class="scroltype"
    @closed="hide()"
    ref="addProject"
  >
    <div
      class="flex justify-between card-header p-5 bgcol w-full xl:rounded-t text-white"
    >
      <h1 class="text-lg">{{ $t("project.components.add.title") }}</h1>
      <button @click="close()">
        <i class="fas fa-times"></i>
      </button>
    </div>

    <div>
      <stepOne v-show="project.activeStep == 1" ref="stepOne" />
      <stepTwo v-show="project.activeStep == 2" ref="stepTwo" />
      <stepThree
        v-show="project.activeStep == 3"
        ref="stepThree"
        :load="project.load"
      />
    </div>
    <div class="px-4">
      <requiredField />
    </div>
    <div class="flex w-full h-16 items-center px-4">
      <div class="flex flex-1">
        <div class="w-6/12">
          <buttonItem
            :text="$t('project.components.add.back')"
            @click="backActive"
            :containerBackground="
              project.activeStep == 1 ? 'bg-gray-400' : 'bgcol'
            "
          />
        </div>
      </div>

      <div class="flex flex-1 justify-center">
        <p class="text-center font-bold textcol">
          {{ project.activeStep }} / 3
        </p>
      </div>

      <div class="flex flex-1 justify-end">
        <div :class="project.activeStep == 3 ? 'w-full' : 'w-1/2'">
          <buttonItem
            v-if="project.activeStep < 3"
            :text="$t('project.components.add.forward')"
            @click="nextActive"
          />

          <div class="flex flex-1 items-center space-x-2 w-full justify-end">
            <buttonItem
              @click="save"
              v-if="project.activeStep == 3"
              :class="project.activeStep == 3 ? 'w-1/2' : 'w-6/12'"
              :load="project.load"
              :text="$t('project.components.add.save')"
              :loadText="$t('project.components.add.saveLoad')"
            />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import requiredField from "@/components/requiredField.vue";
import stepThree from "./utility/stepThree.vue";
import stepOne from "./utility/stepOne.vue";
import stepTwo from "./utility/stepTwo.vue";
import buttonItem from "@/components/button.vue";
import { project } from "@/networking/urlmanager";

export default {
  name: "addProject",
  components: {
    stepThree,
    stepOne,
    stepTwo,
    buttonItem,
    requiredField,
  },
  data() {
    return {
      required: false,
      similarStatus: 1,
      isAdd: 0,
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.project,
      stepOne: (state) => state.project.stepData.stepOne,
      stepTwo: (state) => state.project.stepData.stepTwo,
      stepThree: (state) => state.project.stepData.stepThree,
    }),

    preparePostData() {
      return {
        advisors: this.project.stepData.stepOne.advisors,
        priority: this.project.stepData.stepOne.priority,
        educations: JSON.stringify(this.project.stepData.stepTwo.educationType),
        experienceDuration: JSON.stringify(
          this.project.stepData.stepTwo.experienceDuration
        ),
        projectName: this.project.stepData.stepOne.projectName,
        innovations: this.project.stepData.stepThree.innovations,
        positions: this.project.stepData.stepOne.positionList,
        languages: JSON.stringify(this.project.stepData.stepTwo.languageList),
        companyName: this.project.stepData.stepOne.companyName,
        endDate: this.project.stepData.stepOne.endDate,
        keywords: this.project.stepData.stepThree.keywords,
        institution: JSON.stringify(
          this.project.stepData.stepTwo.institutionList
        ),
        department: JSON.stringify(
          this.project.stepData.stepTwo.departmentList
        ),
        adType: this.project.stepData.stepOne.adType,
        workingModel: this.project.stepData.stepOne.workingModel,
        description: this.project.stepData.stepThree.description,
        cityList: this.project.stepData.stepTwo.cityList,
        recruitmentCount: this.project.stepData.stepOne.recruitmentCount,
        note: this.project.stepData.stepThree.note,
        isCompany: this.project.stepData.stepOne.isCompany,
        similarStatus: this.similarStatus,
        isCompany: this.stepOne.isCompany,
        positionLanguage: this.stepOne.positionLanguage,
      };
    },
  },
  methods: {
    ...mapMutations({
      updateProjectStep: "UPDATE_PROJECT_STEP",
      setProjectActiveStep: "SET_PROJECT_ACTIVE_STEP",
      setStepOneData: "SET_STEP_ONE_DATA",
      setStepTwoData: "SET_STEP_TWO_DATA",
      setStepThreeData: "SET_STEP_THREE_DATA",
      setLoadState: "SET_LOAD_STATE",
      setChanges: "SET_CHANGES",
      setPreviousData: "SET_PREVIOUS_DATA",
      setChangedState: "SET_CHANGED_STATE",
    }),

    ...mapActions({
      saveProject: "saveProject",
      resetProjectData: "resetProjectData",
    }),

    checkRequiredFields() {
      const stepOneFields = {
        companyName: this.stepOne.companyName
          ? true
          : this.$t("project.components.requiredFields.companyName"),
        projectName: this.stepOne.projectName
          ? true
          : this.$t("project.components.requiredFields.projectName"),
        positionListId: this.stepOne.positionList.id
          ? true
          : this.$t("project.components.requiredFields.organizationPosition"),
        priority: this.stepOne.priority
          ? true
          : this.$t("project.components.requiredFields.priority"),
        endDate: this.stepOne.endDate
          ? true
          : this.$t("project.components.requiredFields.endDate"),
        advertType: this.stepOne.adType
          ? true
          : this.$t("project.components.requiredFields.advertType"),
        recruitmentCount: this.stepOne.recruitmentCount
          ? true
          : this.$t("project.components.requiredFields.recruitmentCount"),
        positionLanguage: this.stepOne.positionLanguage
          ? true
          : this.$t("project.components.add.positionLanguage"),
        advisors:
          this.stepOne.advisors.length > 0
            ? true
            : this.$t("project.components.add.advisors"),
        workingModel: this.stepOne.workingModel
          ? true
          : this.$t("project.components.requiredFields.workingModel"),
      };
      const stepTwoFields = {
        educationType:
          this.stepTwo.educationType.length > 0
            ? true
            : this.$t("project.components.requiredFields.education"),

        experienceDuration: this.stepTwo.experienceDuration
          ? true
          : this.$t("project.components.requiredFields.workExperience"),
      };
      const stepThreeFields = {
        keywords:
          this.stepThree.keywords.length > 2
            ? true
            : this.$t("project.components.requiredFields.keywords"),
        innovations:
          this.stepThree.innovations.length > 2
            ? true
            : this.$t("project.components.requiredFields.innovations"),
      };
      let missingFields;
      if (this.project.activeStep === 1) {
        missingFields = stepOneFields;
      } else if (this.project.activeStep === 2) {
        missingFields = stepTwoFields;
      } else if (this.project.activeStep === 3) {
        missingFields = stepThreeFields;
      }

      const errorMessages = Object.values(missingFields).filter(
        (message) => message !== true
      );

      if (errorMessages.length > 0) {
        this.warningMesage(
          this.$t("project.components.add.fillInAllFields") +
            " => " +
            errorMessages.join(" - "),
          5000
        );
        this.required = false;
      } else {
        this.required = true;
      }
    },

    nextActive() {
      this.checkRequiredFields();
      if (this.required && this.project.activeStep <= 3) {
        this.setProjectActiveStep(this.project.activeStep + 1);
      }
    },

    backActive() {
      if (this.project.activeStep <= 3 && this.project.activeStep > 1) {
        this.setProjectActiveStep(this.project.activeStep - 1);
      }
    },

    async show() {
      setTimeout(() => {
        this.companyName = this.$store.state.userData.companyName;
      }, 50);

      if (this.project.changeMessage !== "addProject") {
        this.resetProjectData();
        this.setProjectActiveStep(1);
      }

      this.setChangedState("addProject");

      this.$modal.show("addProject");
    },

    hide() {
      this.$modal.hide("addProject");
    },

    close() {
      if (this.project.changeMessage == "addProject") {
        this.resetProjectData();
      }
      this.$modal.hide("addProject");
    },

    async save() {
      this.checkRequiredFields(); // Validasyon kontrolü
      if (!this.required) {
        return; // Eğer validasyon başarısızsa işlemi durdur
      }
      this.setLoadState(true); // Yükleme durumunu aktif et
      await this.postAdd();
    },

    async postAdd() {
      if (!this.required) {
        // Eğer validasyon geçersizse işlemi durdur
        this.setLoadState(false);
        return;
      }
      try {
        const res = await this.axios.post(project.add, this.preparePostData);
        if (res.data.data.similarStatus == 2) {
          const result = await this.verifiedMessage(
            res.data.message,
            this.$t("project.components.add.verifiedMessage.yes"),
            this.$t("project.components.add.verifiedMessage.no")
          );
          if (result.isConfirmed) {
            this.similarStatus = 2;
            this.setLoadState(false);
            this.save();
            this.close();

            setTimeout(() => {
              this.$emit("refresh", true);
            }, 1000);
          } else if (result.isDismissed) {
            this.similarStatus = 1;
            this.setLoadState(false);
          }
          this.setLoadState(false);
        } else {
          this.successMessage(res.data.message);
          this.close();
          this.$emit("refresh", true);
        }
      } catch (err) {
        this.setLoadState(false);
        console.log(err);
        const errorMessage = err.response
          ? err.response.data.message
          : err.message;
        this.authController(errorMessage);
      }
    },
  },
};
</script>
